<template>
  <div class="catalog-landing">
    <section class="catalog-products-section text-center">
      <h2 class="section-header pb-8 pb-md-16">Product Catalog</h2>
      <Products :itemsCount="8"></Products>

      <v-btn
        depressed
        dark
        height="auto"
        color="primary"
        class="mt-2 mt-sm-12 mt-md-6 px-4 px-sm-12 py-4 see-all-products-btn catalog-btn"
        :to="{name: 'Register'}"
      >
        <img class="mr-2" width="24" height="24" :src="require('@/assets/img/icons/ic-arrow-right-white.svg')" alt="">
        SIGN UP FOR FREE
      </v-btn>
    </section>

    <section class="seasons-section">
      <div class="wrapper">
        <h2 class="section-header text-center mx-auto px-4">
          Roxie is part of every season of life and memory.
        </h2>

        <div class="landing-seasons-wrap">
          <div class="landing-seasons d-flex flex-wrap justify-center mx-auto">
            <div class="landing-seasons-item" v-for="(item, idx) in seasons" :key="idx">
              <img v-if="idx===2" src="@/assets/img/icons/ic-heart-pink.svg" class="floating-heart">
              <div class="landing-seasons-item-img d-flex justify-center align-center">
                <img :src="require(`@/assets/img/landings/catalog/${item.img}`)" alt="">
              </div>
              <div class="landing-seasons-item-label d-flex justify-center align-center">
                <span class="marker-mark--font font-weight-regular">{{ item.label }}</span>
              </div>
              <h6 v-html="item.title" class="mt-7 mt-sm-10 dm-serif-display--font primary--text font-weight-regular"></h6>
              <p class="cochin-lt--font mt-5 mb-0" v-html="item.text"></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <FAQ></FAQ>
  </div>
</template>

<script>
import Products from "@/components/landings/Products";
import FAQ from "@/components/landings/FAQ";

export default {
  name: 'CatalogLanding',
  components: {
    Products,
    FAQ,
  },
  data: () => ({
    seasons: [
      {
        img: 'season-1.png',
        label: 'Mother’s Day',
        title: 'Rock your Mother’s Day <br> gift with Roxie',
        text: 'Make this mother’s day a memorable one! What better gift than curated, fine jewelry personalized to your mother’s unique taste? Browse through Roxie’s configurators and toggle between the various metal, color stone and diamond selections to pick out the perfect gift!'
      },
      {
        img: 'season-2.png',
        label: 'Valentine’s',
        title: 'Valentine’s Day — <br> Give the gift of Roxie',
        text: 'Can’t quite figure out how to show your love on Valentine’s Day? Roxie has you covered! From diamond earrings to personalized bracelets, we offer engravings on a wide variety of our products to make sure your gift is never forgotten! <br><br> Hint: Looking to remind your partner where it all began? Of a place you’ll never forget? Browse through our Coordinates collection and engrave that special place in your memories forever.'
      },
      {
        img: 'birthday.jpeg',
        label: 'Birthday',
        title: 'Every Birthday Deserves a Gift from Roxie',
        text: 'Complete the birthday surprise with a gift from Roxie! From earrings and pendants to rings and bracelets, Roxie’s ever-growing catalog will surely cater to your needs.'
      },
      {
        img: 'graduation.jpeg',
        label: 'Graduation',
        title: 'Celebrate a New Chapter with New Jewelry',
        text: 'What better way to ditch the books and classroom than with highly personalized, quality diamond jewelry? Browse through Roxie now and start searching!'
      },
    ]
  }),
}
</script>
